import "./KshirSagar.css";
import kshirSagarCurd from "../assests/kshirCurd.jpg";
import kshirSagarPeda from "../assests/kshirsagarPeda.jpg";
import kshirSagarGhee from "../assests/kshirGhee.jpg";
import paneerKshir from "../assests/paneerKshir.jpg";
import kshirsagarnewLogo from "../assests/ksheersagarfinalLogo.PNG";
import ksheersagarfactory from '../assests/ksheerSagarFactory.jpg';
import ksheerSagarFrontFactory from '../assests/ksheerSagarFrontFactory.jpg';
import ksheerSagarMachine from '../assests/ksheerSagarMachine.jpg';
import ksheerSagarGenerator from '../assests/ksheerSagarGenerator.jpg';
import { useEffect, useState } from "react";

const KshirSagar = () => {

  const Images = [ksheersagarfactory, ksheerSagarFrontFactory, ksheerSagarMachine, ksheerSagarGenerator];
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setIndex((prevIndex) => (prevIndex === Images.length - 1 ? 0 : prevIndex + 1));
    }, 2500);

    return () => clearInterval(timer);
  }, [index, Images.length]);

  return (
    <div className="main-container">
      <div className="logo-container">
        <img style={{ width: "105px", height: "80px", margin: '8px' }} src={kshirsagarnewLogo} alt="logo" />
        <h1 className="background-logo">KSHIR SAGAR SAMHO DAIRY</h1>
        <img style={{ width: "115px", height: "80px", margin: '8px', float: 'right' }} src={kshirsagarnewLogo} alt="logo" />
      </div>
      <div className="KshirText">
        <p style={{ color: 'red' }}>
          दही, घी, पेड़ा बनाना एक व्यस्त प्रक्रिया हो सकती है। क्षीरसागर उत्पादों के साथ समान ताजगी और पोषण प्राप्त करें।
        </p>
        <p style={{ color: 'red' }}>
          ब्रांड नाम "क्षीर सागर" "सागरमंथन" की पवित्र कहानी से लिया गया है।
        </p >
        <p style={{ color: 'red' }}>विभिन्न प्रकार के उत्पाद चुनें और अपने परिवार के साथ आनंद लें.</p>
        <p>
          Making Curd,Ghee,Peda can be hectic process.Get Same freshness and
          nutrition with KshirSagar Products.
        </p>
        <p>
          The Brand Name "Kshir Sagar" derived from thr holy story of "Sagar
          Manthan".
        </p>
        <p>Choose Different types of Product and Enjoy with your family.</p>
      </div>
      <h1 style={{ textAlign: 'center', textDecorationLine: 'underline', color: 'floralwhite', backgroundColor: 'black' }}>My Products</h1>
      <div style={{ backgroundColor: 'gainsboro' }} className="product-container">
        <div className="text-container">
          <img className="product-logo" src={kshirSagarCurd} alt="logo" />
          <p style={{ color: 'blue' }}>
            क्षीरसागर दही आपके पोषण आहार के लिए आदर्श साथी है। आप इसकी मदद से किसी भी सामान्य भोजन को स्वादिष्ट भोजन में बदल सकते हैं।
          </p>
          <p>
            KshirSagar Curd is your perfect patner for a nutrition diet. You can
            turn any usual meal into tasty food with pure KshirSagar Curd.
          </p>
        </div>
        <div className="text-container">
          <img className="product-logo" src={kshirSagarPeda} alt="logo" />
          <p style={{ color: 'blue' }}>
            क्षीरसागर पेड़ा मुलायम दूध के समान होता है. यह दूध और चीनी से बना है, इसमें इलायची का स्वाद है। इसे मिठाई के रूप में परोसा जा सकता है.
          </p>
          <p>
            KshirSagar Peda is like soft milk Fudge. It made with milk and sugar
            , flavoured with cardemom. It can be served as dessert.
          </p>
        </div>
        <div className="text-container">
          <img className="product-logo" src={kshirSagarGhee} alt="logo" />
          <p style={{ color: 'blue' }}>

            क्षीरसागर घी में आवश्यक फैटी एसिड होते हैं जो शरीर को विभिन्न संक्रमणों से बचाकर प्रतिरक्षा प्रणाली को मजबूत करने में मदद करते हैं।
          </p>
          <p>
            KshirSagar Ghee contains essential fatty acids that help to strengthen the immune system, protecting the body from various infections.
          </p>
        </div>
        <div className="text-container">
          <img className="product-logo" src={paneerKshir} alt="logo" />
          <p style={{ color: 'blue' }}>
            क्षीरसागर पनीर प्रोटीन और पोषण का एक समृद्ध स्रोत है। यह उच्च रक्तचाप को नियंत्रित और कम करने में केंद्रीय भूमिका निभा सकता है।
          </p>
          <p>
            KshirSagar Paneer is a rich source of protein and nutrition. This may regulating and lowering high blood pressure.
          </p>
        </div>
      </div>
      <h1 style={{ textAlign: 'center', textDecorationLine: 'underline', color: 'floralwhite', backgroundColor: 'black' }}>Gallery And Location</h1>
      <div className="product-container">
        <div className="text-container">
          <div className="scrollImage">
            <img style={{ width: '100%', height: '100%' }} src={Images[index]} alt={index} />
          </div>
        </div>
        <div className="image-container">
          <div style={{ backgroundColor: 'dimgray' }}>
            <iframe className="locationGraphics" title='ksheerSagarLoation' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3607.8079154786974!2d86.1943072!3d25.277046299999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f21f0021d81947%3A0xf287e5d50938ca82!2sKsheer%20Sagar%20Shamho%20Dairy!5e0!3m2!1sen!2sin!4v1714819257778!5m2!1sen!2sin" style={{ border: "0" }}></iframe>
          </div>
          <div style={{ fontWeight: 700, fontSize: "20px", color: 'black', height: '250px', borderStyle: 'outset' }}> <p style={{ color: 'blue' }}>Kshir Sagar Samho Dairy , Akbar Pur Barari, Sarlahi, Bihar 851129, contact - 7250933957, 7631257981 , 9931357462 , License Number - 10424150000064</p> क्षीर सागर शाम्हो डेयरी, अकबर पुर बरारी, सरलाही, बिहार 851129, संपर्क - 7250933957, 7631257981, 9931357462, लाइसेंस संख्या - 10424150000064</div>
        </div>
      </div>
    </div>
  );
};

export default KshirSagar;
