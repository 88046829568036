import KshirSagar from './component/KshirSagar';

function App() {
  return (
    <div className="App">
      <KshirSagar />
    </div>
  );
}

export default App;
